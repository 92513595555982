@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Rubik:wght@400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-button-next-custom,
.swiper-button-prev-custom {
  /* Adjust as needed */
}

.swiper-button-prev-custom {
  left: 10px;
  /* Adjust as needed */
}

.swiper-button-next-custom {
  right: 10px;
  /* Adjust as needed */
}

input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text {
  @apply text-primaryColor bg-white transform -translate-y-5 -translate-x-2 scale-75;
}

/* navbar */
.navbar {
  box-shadow: 0 15px 40px -20px rgba(40, 44, 63, 0.15);
}
